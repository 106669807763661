.dashboard {
    box-shadow: 0 0 19px 5px #0000007d;
    transition: all .5s;
    width: 250px;
    height: 100vh;
}


.dashboard ul {
    width: 100%;
    padding: 10px 10px 10px 0;
    overflow: hidden;

}

.dashboard ul li {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;

}

.dashboard ul li a {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 4px #00000037;
    transition: all 0.5s;
    padding: 5px 10px;
    font-size: 18px;

}

.dashboard ul li a.active {
    box-shadow: 0 0 8px #138d13e0;
    font-size: 25px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    color: #138d13;
    font-weight: 600;
}
