.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #282828d2;
    z-index: -1;
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    visibility: hidden;
    opacity: 0;
}

.loading-container.on {
    z-index: 1000;
    visibility: visible;
    opacity: 1;
}


.loading-content {
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 400px;
    align-items: center;
}

.loading-content * {
    color: white;
    text-shadow: 0 0 3px white;
}

.loading-content .title {
    font-size: 30px;
}

.loading-content .message {
    font-size: 18px;
}

.loading-content .spinner{
    /*font-size: 100px;*/
}
:root{
    --spinnerSize:80px;
    --spinnerWeight:10px ;
}

.loading-content .spinner {
    border:  var(--spinnerWeight) solid #f3f3f3; /* Light grey */
    border-top:  var(--spinnerWeight) solid #3498db; /* Blue */
    border-radius: 50%;
    width: var(--spinnerSize);
    height: var(--spinnerSize);
    animation: spin 2s linear infinite;
    box-shadow: 0 0 3px white;

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-content .loading-indicator {
    /*position: fixed;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*z-index: 1000;*/
}
