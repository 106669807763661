body {
    margin: 0;
    padding: 0;
}


.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    overflow: hidden;
}


.container .dashboard-container {
    width: 250px;
    height: 100vh;
    overflow: hidden;
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    transition: all .5s;
    box-shadow: 0 0 19px 5px #0000007d;
}
.container.home .dashboard-container {
    width: 0;
    visibility: hidden;
    opacity: 0;
}



.container .page {
    width: calc(100% - 266px);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.container.home .page{
    width: 100%;
}
.container.home {
    gap: 0;
}

.container .page .content {
    width: 1000px;
    height: 100vh;
    overflow: hidden;
}

.container .page .content.empty {
    display: flex;
    justify-content: center;
    align-items: center;
}
