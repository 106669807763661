.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container form {
    height: 100px;
    width: 400px;
}

.container form input {
    height: 100px;
    width: 400px;
    font-size: 40px;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: 0 0 4px #00000047;
    border-radius: 20px;
    text-align: center;
}
